import { ContentWrapper } from "../containers/alpacked.style"
import EBookContact from "../containers/EbookContact"
import EBookHeader from "../containers/EbookHeader"
import EbookCta from "../containers/EbookCta"
import EbookFaq from "../containers/EbookFaq"
import React from "react"
import SEO from "../components/SEO"
import SimpleLayout from "../containers/Layout/simpleLayout"
import { withPreview } from "gatsby-source-prismic"

const DownloadEbookPage = ({ data, location }) => {
  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="DevOps eBook"
        description="Best practices and roadmaps to implement DevOps into your processes"
      />
      <SimpleLayout pathname="/download-ebook">
        <ContentWrapper>
          <EBookHeader />
          <EbookFaq />
          <EBookContact />
          <EbookCta />
        </ContentWrapper>
      </SimpleLayout>
    </>
  )
}

export default withPreview(DownloadEbookPage)
