import React from "react"

import Container from "../../components/UI/Container"
import EbookContact from "../../common/assets/image/alpacked/ebook/ebook-contact.jpg"
// import Form from "./form"
import TitleSection from "../TitleSection"
import Wrapper, { FormWrapper, ImageWrapper } from "./ebookcontact.style"

const EBookContact = () => {
  const script = `
		https://webforms.pipedrive.com/f/loader
  `
  return (
    <Wrapper id="contact">
      <Container width="1340px" className="intro-title">
        <div
          className="pipedriveWebForms"
          data-pd-webforms="https://webforms.pipedrive.com/f/ccNnKcsMSpN0gwlOnZCMUqMVF11TnacwZQY8OKlvx013LN2bp8SSmJatTR05FRBqQH"
        >
          <script src="https://webforms.pipedrive.com/f/loader">{`
      </div>`}</script>
        </div>
        <TitleSection
          titleMaxWidth="740px"
          titleA="<h2>Reap the full benefits</h2>"
          titleB="of DevOps continuous delivery & integration"
        />
      </Container>
      <Container width="1340px" className="content">
        <FormWrapper>
          {/* <Form /> */}
          <div
            className="pipedriveWebForms"
            data-pd-webforms="https://webforms.pipedrive.com/f/64JM0l1J0uDzgpMsEhhedAMwpJSUHCI4reVofYQq9CHwbOFBOUXvnGkgHxx28BYAin"
          >
            <iframe
              src="https://webforms.pipedrive.com/f/64JM0l1J0uDzgpMsEhhedAMwpJSUHCI4reVofYQq9CHwbOFBOUXvnGkgHxx28BYAin"
              width="100%"
              height="550px"
              frameBorder="0"
              scrolling="no"
              allowTransparency="true"
              allowFullScreen="true"
              title="Ebook Contact Form"
            />
          </div>
        </FormWrapper>
        <ImageWrapper>
          <img src={EbookContact} alt="mission-img" className="image" />
        </ImageWrapper>
      </Container>
    </Wrapper>
  )
}

export default EBookContact
