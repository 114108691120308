import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const Wrapper = styled.div`
  .content {
    position: relative;
    display: flex;
    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }

  .intro-title {
    margin-bottom: 80px;
    @media only screen and (max-width: 1100px) {
      margin-bottom: 0px;
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  left: 58%;
  top: -50%;
  transform: translate(-58%, 2%);
  max-height: 645px;
  margin-top: -8px;
  .image {
    max-width: fit-content !important;
    width: 1100px;
    height: 658px;
  }
  @media only screen and (max-width: 1440px) {
    left: 33%;
    top: -50%;
    transform: translate(-50%, 4%);
    .image {
      width: 600px;
      height: 383px;
    }
  }

  @media only screen and (max-width: 1100px) {
    display: block;
    left: 0;
    top: none;
    transform: none;
    margin-bottom: 30px;
    .image {
      max-width: inherit !important;
      width: 100%;
      height: auto;
    }
  }
  @media only screen and (max-width: 768px) {
    .image {
      width: 100%;
      height: auto;
    }
  }
`

export const FormWrapper = styled.div`
  min-width: 500px;
  padding: 0px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    min-width: 100%;
  }

  .form {
    margin-top: -28px;
  }

  .form-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 40px;
    margin-bottom: 50px;
    @media (max-width: 1100px) {
      grid-template-columns: 100%;
      grid-gap: 0px;
      margin-bottom: 0px;
    }
  }

  .reusecore__button {
    width: 200px;
    background-color: ${themeGet("colors.maiBlue")};
    color: ${themeGet("colors.white")};
    font-size: 18px;
    border-radius: 0px;
    padding: 16px 21px;
    text-transform: capitalize;
    margin-top: 50px;

    &:hover {
      background-color: ${themeGet("colors.mainBlueHover")};
    }
    @media only screen and (max-width: 568px) {
      font-size: 18px;
      width: 100%;
    }
  }
`

export default Wrapper
